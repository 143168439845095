import Button from 'components/home/common/Button'
import { useFormContext } from 'react-hook-form'
import { DestinationSearchFormFields } from 'components/home/navbar/destination/DestinationNavbar'
import ControlledNumberInput from 'components/form/Home/ControlledNumberInput'
import { PropertyNavbarFormFields } from 'components/property/PropertyNavbar'
import { useMemo, useState } from 'react'
import PublicMobileModal from 'components/PublicMobileModal'
import { ReactChild } from 'data/types/types'

interface GuestsSelectionModalProps {
  renderTrigger: (({
    open,
    setOpen,
    adults,
    children,
    guests,
  }: {
    open: boolean,
    setOpen: (open: boolean) => void,
    adults: number,
    children: number
    guests: number
  }) => ReactChild)
}

const GuestsSelectionModal = ({
  renderTrigger,
}: GuestsSelectionModalProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const { control, watch, formState: { errors } } = useFormContext<DestinationSearchFormFields | PropertyNavbarFormFields>()

  const numberOfGuests = useMemo(
    () => (watch('adults') ?? 0) + (watch('children') ?? 0),
    [watch(['adults', 'children'])],
  )

  return (
    <>
      {
        renderTrigger({
          open: isOpen,
          setOpen: setIsOpen,
          adults: watch('adults') ?? 0,
          children: watch('children') ?? 0,
          guests: numberOfGuests,
        })
      }

      <PublicMobileModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Guests"
        className={{
          panel: '!max-h-max h-full px-24 pb-safe-offset-30',
        }}
      >
        <div className="flex flex-col items-center pt-20">
          <ControlledNumberInput
            placeholder="0"
            label="Adults"
            sublabel="18 or older"
            control={control}
            name="adults"
            error={errors.adults}
            className={{ container: 'pb-24' }}
          />

          <ControlledNumberInput
            name="children"
            control={control}
            placeholder="0"
            label="Children"
            sublabel="Under 18"
            error={errors.children}
            className={{ container: 'pb-40' }}
          />

          <Button
            style="pill"
            onClick={() => setIsOpen(false)}
          >
            Confirm Guests
          </Button>
        </div>
      </PublicMobileModal>
    </>
  )
}

export default GuestsSelectionModal
