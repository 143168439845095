import { LoadingIcon, MapPinIcon } from 'components/icons'
import ControlledDestinationsSelections, { ControlledDestinationsSelectionsFormFields } from 'components/modals/home/destinations/ControlledDestinationsSelection'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import ControlledNavbarPopover from 'components/home/navbar/popovers/components/ControlledNavbarPopover'
import classNames from 'classnames'

interface DestinationSelectionPopoverProps {
  placeholder?: string
  className?: {
    container?: string
    button?: string
    panel?: string
  }
}

const DestinationSelectionPopover = ({
  className,
  placeholder = 'Where to next?',
}: DestinationSelectionPopoverProps) => {
  const { control, setValue } = useFormContext<ControlledDestinationsSelectionsFormFields>()

  const { searchingDestinations } = useSelector((state: RootState) => ({
    searchingDestinations: state.searchbar.searchingDestinations,
  }))

  return (
    <ControlledNavbarPopover
      className={{
        container: classNames('flex-grow', className?.container),
        button: classNames('border-b border-transparent focus-within:border-primary-fresh-light', className?.button),
        panel: classNames('top-[85px] !max-w-[590px] !max-h-[700px] flex flex-col overflow-y-auto', className?.panel),
      }}
      trigger={({ setIsOpen }) => (
        <>
          <label
            htmlFor="destination_search"
            className="text-primary-fresh text-20"
          >
            <MapPinIcon />
          </label>

          <Controller
            name="destination_search"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <input
                id="destination_search"
                type="text"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)

                  if (e.target.value === '') {
                    setValue('destination', undefined)
                  }
                }}
                onFocus={() => setIsOpen(true)}
                onBlur={onBlur}
                className="w-full border-none focus:outline-none focus:ring-0 text-grey-800 leading-20 text-15 pl-15 placeholder-grey-700"
                placeholder={placeholder}
              />
            )}
          />

          {
            searchingDestinations && (
              <div className="inset-y-0 flex items-center justify-center pr-10 right-10">
                <LoadingIcon className="w-16 h-16" />
              </div>
            )
          }
        </>
      )}
    >
      {({ isOpen, setIsOpen }) => (
        <ControlledDestinationsSelections
          pause={!isOpen}
          redirect={false}
          onSelect={() => setIsOpen(false)}
          padding="lg"
        />
      )}
    </ControlledNavbarPopover>
  )
}

export default DestinationSelectionPopover
