import { MobileScreenWidths, useScreenWidth } from 'data/hooks/useScreenWidth'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import PublicSearchBarMobile from './PublicSearchBarMobile'
import DestinationNavbar from './home/navbar/destination/DestinationNavbar'
import FloatingDestinationNavbar from './home/navbar/destination/FloatingDestinationNavbar'

interface HomepageSearchBarProps {
  form: UseFormReturn<any>
}

const HomepageSearchBar = ({
  form,
}: HomepageSearchBarProps) => {
  const { isMobile } = useScreenWidth(MobileScreenWidths.LG)

  return isMobile
    ? <PublicSearchBarMobile form={form} />
    : <>
      <DestinationNavbar
        className={{
          container: 'left-0',
        }}
        scrollDelay={650}
      />

      <FloatingDestinationNavbar />
    </>
}

export default HomepageSearchBar
