import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import GuestReviewItem from 'components/GuestReviewItem'
import { Autoplay, EffectFade } from 'swiper/modules'
import { useQuery } from 'urql'
import { graphql } from 'gql'
import Headers from 'components/home/common/typography/Headers'

const GuestReviewsSection = () => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query GuestReviews {
        cmsGuestReviews(first: 100) {
          data {
            review
            reviewer
            property {
              id
              title
              url
            }
          }
        }
      }
    `),
  })

  return (
    <div className="flex border-b-[1px] border-grey-150 flex-col items-center justify-center text-center py-75 lg:py-[125px]">
      <Headers.Title Tag="h2" className="font-normal text-center text-grey-900">
        Guest Reviews
      </Headers.Title>

      <div className="relative w-full max-w-5xl text-left">
        <Swiper
          modules={[Autoplay, EffectFade]}
          autoplay={{ delay: 5000 }}
          effect="fade"
          fadeEffect={{ crossFade: true }}
          loop={true}
        >
          {
            fetching
              ? null
              : data?.cmsGuestReviews?.data?.map((review) => (
                <SwiperSlide key={review.review}>
                  <GuestReviewItem
                    reviewer={review.reviewer}
                    villa={review.property}
                  >
                    {review.review}
                  </GuestReviewItem>
                </SwiperSlide>
              ))
          }
        </Swiper>
      </div>
    </div>
  )
}

export default GuestReviewsSection
