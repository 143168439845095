import React from 'react'
import mainpageIntroImage from 'assets/images/mainpage-intro.png'
import mainpageTailored from 'assets/images/mainpage-tailored.png'
import Image from 'components/Image'
import InformationSection from './InformationSection'

const OfficialPartners = () => {
  return (
    <InformationSection
      image={{
        url: mainpageTailored.src,
        altText: 'Homes & Villas',
      }}
      title={'A Tailored Villa\nRental Experience'}
      className={{
        container: 'mt-25 mb-75 lg:mt-75 lg:mb-[125px] !max-w-[1150px]',
        overrideContainerPadding: true,
        content: {
          title: 'whitespace-break-spaces',
          text: 'mt-20',
          container: 'max-w-[562px]',
        },
      }}
      inlinelink={{
        href: '/about-us',
        label: 'read more',
      }}
      text="From exclusive properties to world class services, we tailor authentic and memorable holidays for our guests. Our in-house concierge team excels in translating your vision into a seamless vacation experience..."
      footer={
        <div className="flex flex-col items-center">
          <div className="relative mt-60 max-w-[400px] h-100 w-full">
            <Image
              className="inset-0 object-contain"
              src={mainpageIntroImage}
              alt="Homes & Villas"
              layout="fill"
              priority
            />
          </div>
          <p className="mt-[18px] lg:mt-10 font-normal uppercase text-12 tracking-1 text-grey-750 font-sans leading-22 text-center w-full">Official Partner</p>
        </div>
      }
    />
  )
}

export default OfficialPartners
