import { MapPinIcon } from 'components/icons'
import { useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import MobileDestinationsModal from 'components/modals/home/destinations/MobileDestinationsModal'
import { DashboardSearchFormFields } from 'data/types/home/search'
import PublicMobileModal from 'components/PublicMobileModal'
import PropertySearchTab from 'components/modals/home/search/properties/PropertySearchTab'

export interface PropertySearchModalFormProps {
  onClose: () => void
}

interface PropertySearchModalProps {
  isOpen: boolean
  onClose: () => void
  initialDestination?: SelectedDestinationData
}

const PropertySearchModal = ({
  isOpen,
  onClose,
}: PropertySearchModalProps) => {
  const [destinationsModal, setDestinationsModal] = useState<{ isOpen: boolean }>({ isOpen: false })

  const formProps = useFormContext<DashboardSearchFormFields>() ?? useForm<DashboardSearchFormFields>()

  return (
    <PublicMobileModal
      isOpen={isOpen}
      onClose={onClose}
      className={{
        panel: 'h-full',
      }}
    >
      <FormProvider {...formProps}>
        <form className="flex flex-col h-full px-24 pt-10 pb-16">
          <button
            type="button"
            className="flex flex-col w-full gap-10 py-10 text-left"
            onClick={() => setDestinationsModal({ isOpen: true })}
          >
            <div className="flex items-center flex-1 w-full gap-12 text-grey-650">
              <MapPinIcon className="text-primary-fresh stroke-[0.5px]" />
              <span>Where to next?</span>
            </div>

            {
              formProps.watch('destination') && (
                <Paragraphs.XL className="w-full font-bold text-grey-900 leading-20">
                  {formProps.watch('destination')?.value}
                </Paragraphs.XL>
              )
            }
          </button>

          <hr className="border border-grey-100 mb-21" />

          <PropertySearchTab onClose={onClose} />

          <MobileDestinationsModal
            isOpen={destinationsModal.isOpen}
            onClose={() => setDestinationsModal({ isOpen: false })}
            redirect={false}
          />
        </form>
      </FormProvider>
    </PublicMobileModal>
  )
}

export default PropertySearchModal
