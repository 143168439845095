import FixedNavbar from 'components/home/navbar/FixedNavbar'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { DateRangeInput } from 'components/dates/DateRangePicker'
import { PropertyFilterFields } from 'components/modals/home/search/properties/PropertyFiltersModal'
import { SelectedDestinationData } from 'components/popovers/DestinationSelectionPopover/_types'
import NavbarPopover from '../popovers/components/NavbarPopover'
import NavbarButton from '../common/NavbarButton'
import { FilterSearchBarIcon, SearchAltIcon } from 'components/icons'
import Button from 'components/home/common/Button'
import { getNumericQueryParameter, getSearchURLQueryParameters, parseDate, pluraliseWithNumber } from '@ama-selections/ui'
import ControlledPropertyFilters from 'components/modals/home/search/properties/ControlledPropertyFilters'
import { compact } from 'lodash'
import { useEffect, useMemo } from 'react'
import DestinationSelectionPopover from 'components/home/navbar/popovers/specific/DestinationSelectionPopover'
import DateRangeSelectionPopover from '../popovers/specific/DateRangeSelectionPopover'
import { getDestinationSearchPathname } from 'data/helpers/destination'
import { useRouter } from 'next/router'
import { useDestinationFetching } from 'data/hooks/Destinations/useDestinationFetching'
import { useHasScrolled } from 'data/hooks/useHasScrolled'
import { useSearchParams } from 'data/hooks/useSearchParams'
import { getNumericArrayQueryParameter, getStringArrayQueryParameter } from '@ama-selections/ui/src/helpers/destination'
import GuestsSelectionPopover from '../popovers/specific/GuestsSelectionPopover'

interface DestinationNavbarProps {
  initialDestination?: SelectedDestinationData
  scrollDelay?: number
  className?: {
    container?: string,
  },
}

export type DestinationSearchFormFields = {
  adults: number | undefined
  children: number | undefined
  dates: DateRangeInput | undefined
  filters: PropertyFilterFields | undefined
  destination: SelectedDestinationData | undefined
  destination_search: string | undefined
}

const DestinationNavbar = ({
  initialDestination,
  className,
  scrollDelay,
}: DestinationNavbarProps) => {
  const router = useRouter()

  const formProps = useFormContext<DestinationSearchFormFields>() ?? useForm<DestinationSearchFormFields>()

  const { isDestinationFetching } = useDestinationFetching()

  const hasScrolled = useHasScrolled(scrollDelay)

  const { params } = useSearchParams()
  useEffect(() => {
    formProps.reset({
      adults: formProps.getValues('adults') ?? getNumericQueryParameter(params.get('adults')) ?? undefined,
      children: formProps.getValues('children') ?? getNumericQueryParameter(params.get('children')) ?? undefined,
      dates: {
        startDate: formProps.watch('dates')?.startDate ?? (params.get('arrival') ? parseDate(params.get('arrival'))?.toDate() : undefined),
        endDate: formProps.watch('dates')?.endDate ?? (params.get('departure') ? parseDate(params.get('departure'))?.toDate() : undefined),
      },
      filters: {
        bedrooms: formProps.getValues('filters.bedrooms') ?? getNumericQueryParameter(params.get('bedrooms')),
        bathrooms: formProps.getValues('filters.bathrooms') ?? getNumericQueryParameter(params.get('bathrooms')),
        minimumPrice: formProps.getValues('filters.minimumPrice') ?? getNumericQueryParameter(params.get('minimumPrice')),
        maximumPrice: formProps.getValues('filters.maximumPrice') ?? getNumericQueryParameter(params.get('maximumPrice')),
        propertyTypes: formProps.getValues('filters.propertyTypes') ?? getNumericArrayQueryParameter(params.get('propertyTypes')),
        searchCategories: formProps.getValues('filters.searchCategories') ?? getStringArrayQueryParameter(params.get('searchCategories')),
      },
      destination: formProps.getValues('destination') ?? initialDestination,
      destination_search: formProps.getValues('destination_search') ?? initialDestination?.value,
    })
  }, [hasScrolled, initialDestination])

  const numberOfAppliedFilters = useMemo(() => {
    const filters = formProps.watch('filters')

    if (!filters) {
      return 0
    }

    let selectedLength = compact(Object.values(filters)).length

    if (filters.searchCategories) {
      selectedLength += filters.searchCategories.length - 1
    }

    if (filters.propertyTypes) {
      selectedLength += filters.propertyTypes.length - 1
    }

    return selectedLength
  }, [formProps.watch([
    'filters.bathrooms',
    'filters.bedrooms',
    'filters.maximumPrice',
    'filters.minimumPrice',
    'filters.propertyTypes',
    'filters.searchCategories',
  ])])

  return (
    <FixedNavbar
      className={{
        ...className,
        children: 'max-w-[854px]',
      }}
      scrollDelay={scrollDelay}
      hideChildrenOnMobile
    >
      <FormProvider {...formProps}>
        <form className="flex flex-grow divide-x divide-grey-250">
          <DestinationSelectionPopover
            className={{
              container: '!top-0',
              panel: 'mr-auto -ml-40',
            }}
          />

          <DateRangeSelectionPopover />

          <GuestsSelectionPopover
            className={{
              container: '!min-w-[206px] gap-30',
            }}
          />

          <NavbarPopover
            trigger={
              <NavbarButton
                prefix={<FilterSearchBarIcon />}
                active={numberOfAppliedFilters > 0}
                className={{
                  container: '!min-w-[170px]',
                }}
              >
                {pluraliseWithNumber(
                  numberOfAppliedFilters,
                  'Filter',
                  { showPlaceholderOnNull: true },
                )}
              </NavbarButton>
            }
            variant="fixed"
            className={{
              panel: '!pb-0 !pt-40',
            }}
          >
            {({ close }) => (
              <ControlledPropertyFilters
                onFilter={close}
                button={{
                  style: 'pill',
                }}
              />
            )}
          </NavbarPopover>
        </form>

        <div className="flex items-center">
          <Button
            style="pill"
            className={{
              button: '!py-[7px] !px-[21px]',
            }}
            href={{
              pathname: getDestinationSearchPathname(formProps.watch('destination')),
              query: getSearchURLQueryParameters({
                sort: router?.query?.sort,
                adults: formProps.watch('adults'),
                children: formProps.watch('children'),
                arrival: formProps.watch('dates')?.startDate,
                departure: formProps.watch('dates')?.endDate,
                bedrooms: formProps.watch('filters')?.bedrooms,
                bathrooms: formProps.watch('filters')?.bathrooms,
                minimumPrice: formProps.watch('filters')?.minimumPrice,
                maximumPrice: formProps.watch('filters')?.maximumPrice,
                propertyTypes: formProps.watch('filters')?.propertyTypes,
                searchCategories: formProps.watch('filters')?.searchCategories,
              }),
            }}
            isLoading={isDestinationFetching}
          >
            <SearchAltIcon className="text-18" />
          </Button>
        </div>
      </FormProvider>
    </FixedNavbar>
  )
}

export default DestinationNavbar
