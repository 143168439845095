import React from 'react'
import CarouselDestinations from 'components/carousels/CarouselDestinations'
import { useQuery } from 'urql'
import { graphql } from 'gql'
import Headers from 'components/home/common/typography/Headers'
import Paragraphs from 'components/home/common/typography/Paragraphs'

const DestinationsSection = () => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query FeaturedRegions {
        cmsPopularDestinations(first: 100) {
          data {
            description
            region {
              id
              slug
              value
              description
              main_media {
                id
                url
                file_name
              }
              country {
                id
                value
                slug
              }
            }
          }
        }
      }
    `),
  })

  return (
    <>
      <div className="flex flex-col items-center justify-center text-center my-75 lg:my-[125px] font-normal">
        {
          fetching
            ? null
            : (
              <div className="w-full">
                <Headers.Title Tag="h2" className="font-medium text-grey-900">
                  Popular Destinations
                </Headers.Title>

                <Paragraphs.XL className="mx-auto max-w-[300px] lg:max-w-full mt-14 text-grey-700">
                  Browse some of the most exclusive luxury villas in the world's fashionable destinations for {new Date().getFullYear()}.
                </Paragraphs.XL>

                <div className="mt-40 destinations-carousel">
                  <CarouselDestinations destinations={data?.cmsPopularDestinations?.data ?? []} />
                </div>
              </div>
            )
        }
      </div>

      <hr className="border-b-1 border-home-divider" />
    </>
  )
}

export default DestinationsSection
