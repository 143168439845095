import React from 'react'
import CarouselFeaturedProperties from 'components/carousels/CarouselFeaturedProperties'
import { useQuery } from 'urql'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import Headers from 'components/home/common/typography/Headers'
import { graphql } from 'gql'

const FeaturedPropertiesSection = () => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query FeaturedPropertiesSection {
        cmsFeaturedProperties(first: 18) {
          data {
            id
            url
            main_image_alt_text
            main_image {
              id
              url
            }
            title
            region {
              id
              value
            }
            country {
              id
              value
            }
          }
        }
      }
    `),
  })

  return (
    <div className="flex border-b-[1px] border-home-divider flex-col items-center justify-center text-center py-75 lg:py-[125px]">
      <div className="w-full mx-auto max-w-7xl">
        {
          !fetching && (
            <div className="w-full">
              <Headers.Title Tag="h2" className="font-medium text-grey-900">
                Featured Properties
              </Headers.Title>

              <Paragraphs.XL className="font-normal mt-15 px-25 text-grey-750 opacity-90">
                Europe & Caribbean top luxury villa rentals for {new Date().getFullYear()}
              </Paragraphs.XL>

              <div className="relative mt-40">
                <CarouselFeaturedProperties featuredProperties={data?.cmsFeaturedProperties?.data ?? []} />
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default FeaturedPropertiesSection
