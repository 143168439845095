import React from 'react'
import becomeAMemberBackground from 'assets/images/become-a-member-background.jpg'
import { useQuery } from 'urql'
import { graphql } from 'gql'
import BannerCardSection from './BannerCardSection'
import { ArrowRightIcon } from 'components/icons'

const BecomeAMemberSection = () => {
  const [{ data, fetching }] = useQuery({
    query: graphql(`
      query BecomeAMemberSection {
        cmsBecomeAMemberSection {
          heading
          content
          background_image
        }
      }
    `),
  })

  return fetching
    ? (<></>)
    : (
      <BannerCardSection
        className={{
          container: 'my-50 md:my-150',
        }}
        heading={data?.cmsBecomeAMemberSection?.heading}
        content={data?.cmsBecomeAMemberSection?.content}
        subtitle="Become A Member"
        includePadding={false}
        bannerImage={data?.cmsBecomeAMemberSection?.background_image ?? becomeAMemberBackground.src}
        altText="Become a member background"
        buttonHref="/membership"
        buttonText="Read More"
        buttonIcon={<ArrowRightIcon />}
      />
    )
}

export default BecomeAMemberSection
