import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { CalendarSearchBarIcon, FilterSearchBarIcon, MapPinIcon, UserOutlineIcon } from './icons'
import Input from './form/Home/Input'
import Button from './home/common/Button'
import ControlledDateRangePickerModal from './dates/ControlledDateRangePickerModal'
import { getSearchURLQueryParameters, joinBy, pluraliseWithNumber } from '@ama-selections/ui'
import MobileDestinationsModal from './modals/home/destinations/MobileDestinationsModal'
import PropertyFiltersModal from './modals/home/search/properties/PropertyFiltersModal'
import { compact } from 'lodash'
import { PropertyFilterFields } from './modals/home/search/properties/ControlledPropertyFilters'
import { getDestinationSearchPathname } from 'data/helpers/destination'
import { useRouter } from 'next/router'
import GuestsSelectionModal from './home/navbar/popovers/specific/GuestSelectionModal'

interface PublicSearchBarMobileProps {
  form: UseFormReturn<any>
  fixed?: boolean
}

const PublicSearchBarMobile = ({
  form,
}: PublicSearchBarMobileProps) => {
  const router = useRouter()
  const [isDestinationOpen, setIsDestinationOpen] = useState(false)
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)

  const calculateFilterValue = (filters?: PropertyFilterFields) => {
    if (!filters) {
      return null
    }

    let selectedLength = compact(Object.values(filters)).length

    if (filters.searchCategories) {
      selectedLength += filters.searchCategories.length - 1
    }

    if (filters.propertyTypes) {
      selectedLength += filters.propertyTypes.length - 1
    }

    return selectedLength > 0 ? `${pluraliseWithNumber(selectedLength, 'Filter')} Selected` : ''
  }

  return (
    <>
      <div className="relative flex flex-col w-full gap-8 px-25">
        <Input
          variant="searchbar"
          prefix={<MapPinIcon />}
          placeholder="Where to next?"
          value={form.watch('destination_search')}
          readOnly
          onClick={() => setIsDestinationOpen(true)}
        />

        <ControlledDateRangePickerModal
          renderButton={({ onClick }) => (
            <Input
              variant="searchbar"
              prefix={<CalendarSearchBarIcon />}
              placeholder="Dates"
              value={joinBy([
                form.watch('dates.startDate')?.toDateString(),
                form.watch('dates.endDate')?.toDateString(),
              ], ' - ')}
              readOnly
              onClick={onClick}
            />
          )}
          datePickerProps={{
            control: form.control,
            name: 'dates',
          }}
          onClear={() => form.setValue('dates', {
            startDate: undefined,
            endDate: undefined,
          })}
        />

        <GuestsSelectionModal
          renderTrigger={({ setOpen }) => (
            <Input
              variant="searchbar"
              placeholder="Guests"
              prefix={<UserOutlineIcon />}
              value={joinBy([
                pluraliseWithNumber(
                  form.watch('adults') ?? 0,
                  'Adult',
                  {
                    nullable: true,
                  },
                ),
                pluraliseWithNumber(
                  form.watch('children') ?? 0,
                  'Child',
                  {
                    pluralEnding: 'ren',
                    nullable: true,
                  },
                ),
              ], ', ')}
              readOnly
              onClick={() => setOpen(true)}
            />
          )}
        />

        <Input
          variant="searchbar"
          prefix={<FilterSearchBarIcon />}
          placeholder="Filters"
          onClick={() => setIsFiltersOpen(!isFiltersOpen)}
          value={calculateFilterValue(form.watch('filters')) ?? undefined}
          readOnly
        />

        <Button
          block
          variant="primary"
          href={{
            pathname: getDestinationSearchPathname(form.watch('destination')),
            query: getSearchURLQueryParameters({
              sort: router?.query?.sort,
              adults: form.watch('adults'),
              children: form.watch('children'),
              arrival: form.watch('dates')?.startDate,
              departure: form.watch('dates')?.endDate,
              bedrooms: form.watch('filters')?.bedrooms,
              bathrooms: form.watch('filters')?.bathrooms,
              minimumPrice: form.watch('filters')?.minimumPrice,
              maximumPrice: form.watch('filters')?.maximumPrice,
              propertyTypes: form.watch('filters')?.propertyTypes,
              searchCategories: form.watch('filters')?.searchCategories,
            }),
          }}
        >
          Search
        </Button>

        <hr className="mt-50 border-grey-230 mb-25"/>
      </div>

      <PropertyFiltersModal
        isOpen={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onFilter={() => {
          setIsFiltersOpen(false)
        }}
      />

      <MobileDestinationsModal
        isOpen={isDestinationOpen}
        onClose={() => setIsDestinationOpen(false)}
        redirect={false}
      />
    </>
  )
}

export default PublicSearchBarMobile
