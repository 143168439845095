import React from 'react'
import { useQuery } from 'urql'
import { graphql } from 'gql'
import ServiceSlider from './ServiceSlider'

const ServicesSection = () => {
  const [{ data }] = useQuery({
    query: graphql(`
      query FeaturedServices {
        featuredServices(first: 9) {
          data {
            id
            title
            slug
            survey_slug
            description
            service_image {
              id
              url
            }
            image_alt_text
            form_link
          }
        }
      }
    `),
  })

  return (<>
    <ServiceSlider
      className={{
        container: 'my-50 lg:my-100',
      }}
      subheading="Services & Experiences"
      heading="Seamlessly add bespoke services and experiences to your stay"
      description="We aim to provide an incomparable level of discreet, tailored service. Upon booking, you will be introduced to your dedicated concierge who will assist you in tailoring your stay to create an unforgettable holiday. Easily manage and share your trip itinerary with friends and family directly from your account."
      button={{
        href: '/services/all',
        text: 'View Services',
      }}
      items={data?.featuredServices?.data.map((service) => {
        const [subheading, pillText] = (service?.description ?? '').split('\n')
        return {
          heading: service.title,
          subheading: subheading,
          image: {
            media: service.service_image?.url,
            alt_text: service.image_alt_text,
          },
          href: `/services/${service.slug}`,
          pillText: pillText ? [pillText] : [],
        }
      }) ?? []}
    />
  </>
  )
}

export default ServicesSection
