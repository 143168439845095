import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFetching } from 'store/searchbar'

export const useDestinationFetching = (fetching, page) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const destinationFetching = useSelector(state => state.searchbar.fetching)

  useEffect(() => {
    if (typeof fetching === 'boolean' && page === 1) {
      setIsDestinationFetching(fetching)
    }
  }, [fetching, page])

  useEffect(() => {
    if (router.route !== '/search' || !router.route.includes('[country]')) {
      setIsDestinationFetching(false)
    }
  }, [router.route])

  const setIsDestinationFetching = (isFetching) => {
    dispatch(setFetching(isFetching))
  }

  return {
    isDestinationFetching: destinationFetching,
    setIsDestinationFetching,
  }
}
